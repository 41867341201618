<template>
  <div>
    <org-setup-success v-if="newOrg" />
    <div class="d-flex justify-content-between">
      <h3>OrgHQ</h3>
      <h3 v-if="org">
        {{ org.name }}
      </h3>
    </div>
    <b-tabs content-class="mt-3" v-if="org">
      <b-tab title="Details" active v-if="isViewerOf()(organisationId)">
        <org-details
          :org-id="organisationId"
          :edit-contacts="editContacts"
          :edit-mission="editMission"
          :edit-org="editOrg"
        />
      </b-tab>
      <b-tab title="Users">
        <members :orgId="organisationId" :members="org.members" />
      </b-tab>
      <b-tab title="Roles" v-if="isAdminOf()(organisationId)">
        <role-legend :org-id="organisationId" :show-edit="true" />
      </b-tab>
      <b-tab title="Security" v-if="isAdminOf()(organisationId)">
        <security :org-id="organisationId" :org-mfa-enabled="org.mfaEnabled" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {mapActions, mapState, mapGetters} from "vuex";
import Members from "./Members";
import OrgDetails from "./OrgDetails";
import RoleLegend from "../role/RoleLegend";
import Security from "./Security";
import OrgSetupSuccess from "@/components/org/OrgSetupSuccess";

export default {
  name: "Organisation",
  components: {OrgSetupSuccess, RoleLegend, Members, OrgDetails, Security},
  props: {
    organisationId: String,
    editContacts: Boolean,
    editMission: Boolean,
    editOrg: Boolean,
    newOrg: Boolean,
  },
  computed: {
    ...mapState("orgStore", {orgs: state => state.orgs}),
    org() {
      return this.orgs.find(org => org.id === this.organisationId);
    },
  },
  mounted() {
    this.fetchOrg(this.organisationId).catch(error =>
      console.log("Error fetching org. " + error)
    );
  },
  methods: {
    ...mapActions("orgStore", ["fetchOrg"]),
    ...mapGetters("auth", ["isAdminOf","isViewerOf"]),
  },
};
</script>

<style>
.nav-tabs .nav-link:hover {
  text-decoration: underline;
}
</style>
