<template>
  <div>
    <div class="d-sm-flex mb-2">
      <div class="flex-grow-1 mr-3 mb-2">
        <b-form-group label="First name" label-for="name">
          <b-form-input id="firstName" v-model="copyContact.firstName" />
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-3 mb-2">
        <b-form-group label="Last name" label-for="name">
          <b-form-input id="lastName" v-model="copyContact.lastName" />
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-3 mb-2">
        <b-form-group label="Position" label-for="name">
          <b-form-input id="position" v-model="copyContact.position" />
        </b-form-group>
      </div>
    </div>

    <div>
      <individual-contact-edit
        :contact="copyContact"
        ref="contact"
        @addRow="addRow"
        @remove="removeEntry"
        @tagSelect="onTagSelect"
        @addRowAddress="addRowAddress"
      />
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";
import {
  forceUniqueTags,
  clearEmptyContactFields,
  clearEmptyAddressFields,
  scrollToError,
} from "@/utils/utils";
import {log} from "@/utils/log";
import IndividualContactEdit from "../interface/IndividualContactEdit";

export default {
  name: "HeadOfOrgEdit",
  components: {
    IndividualContactEdit,
  },
  props: ["org"],
  data() {
    return {
      /**
       * A partial copy of the head of org to avoid directly modifying data from the Org store
       */
      copyContact: {
        id: this.org.headOfOrg.id,
        firstName: this._.cloneDeep(this.org.headOfOrg.firstName),
        lastName: this._.cloneDeep(this.org.headOfOrg.lastName),
        position: this._.cloneDeep(this.org.headOfOrg.position),
        emails: this._.cloneDeep(this.org.headOfOrg.emails),
        phoneNumbers: this._.cloneDeep(this.org.headOfOrg.phoneNumbers),
        websites: this._.cloneDeep(this.org.headOfOrg.websites),
        addresses: this._.cloneDeep(this.org.headOfOrg.addresses),
      },
      firstName: "",
    };
  },
  methods: {
    ...mapActions("orgStore", ["fetchOrg", "patchOrg"]),
    addRow(arrayName, name) {
      const row = {
        [name]: "",
        tags: [],
      };
      if (this.copyContact[arrayName].length === 0) {
        row.tags = ["Primary"];
      }
      this.copyContact[arrayName].push(row);
    },
    addRowAddress() {
      const row = {
        addressLine1: "",
        addressLine2: "",
        country: "",
        postcode: "",
        state: "",
        suburbLocality: "",
        tags: [],
      };
      this.copyContact.addresses.push(row);
    },
    checkErrors() {
      console.log("Checking errors: " + this.$refs.contact);
      return this.$refs.contact.invalidFields();
    },
    clearEmptyData() {
      clearEmptyContactFields(this.copyContact.emails, "email");
      clearEmptyContactFields(this.copyContact.phoneNumbers, "number");
      clearEmptyContactFields(this.copyContact.websites, "url");
      clearEmptyAddressFields(this.copyContact.addresses);
    },
    save() {
      // First clear fully empty rows
      this.clearEmptyData();
      const checkedErrors = this.checkErrors();
      if (checkedErrors.length > 0) {
        log.debug("Errors detected when saving contact details");
        scrollToError(checkedErrors[0]);
        return Promise.reject();
      }
      this.copyContact.emails.forEach(x => (x.email = x.email.toLowerCase()));
      const updateObj = this._.cloneDeep(this.org);
      updateObj.headOfOrg = this.copyContact;
      if (!updateObj.headOfOrg.firstName) {
        updateObj.headOfOrg.firstName = "";
      }
      if (!updateObj.headOfOrg.lastName) {
        updateObj.headOfOrg.lastName = "";
      }
      if (!updateObj.headOfOrg.position) {
        updateObj.headOfOrg.position = "";
      }
      return this.patchOrg(updateObj)
        .then(() => "Contacts Updated")
        .catch(err => {
          // TODO when API fails validation this should be caught and shown as validation errors
          // Check the type of error, if it's a validation error display here.
          // Rethrow the error to the parent component to indicate the operation failed
          handleApiError(err, this);
          throw err;
        });
    },
    onTagSelect(name, contactArray, selectedEntry) {
      this.copyContact[name] = forceUniqueTags(contactArray, selectedEntry);
    },
    removeEntry(name, obj) {
      this.copyContact[name] = this.copyContact[name].filter(
        element => element !== obj
      );
    },
  },
};
</script>
