<template>
  <div>
    <b-table-lite :fields="fields" :items="items" :stacked="breakpoint" striped>
      <template v-slot:head(CUSTOM)>
        Custom
        <sup>
          <b-icon
              icon="info-circle"
              variant="primary"
              aria-hidden="true"
              id="custom-popover"
          />
          <b-popover
              target="custom-popover"
              triggers="hover"
              placement="right"
          >
            Set customised permission if none of the default roles suit your users.
          </b-popover>
        </sup>
      </template>
      <template v-slot:cell(label)="data">
        <span>{{data.item.label}}
          <sup v-if="data.item.info">
            <b-icon
                icon="info-circle"
                variant="primary"
                aria-hidden="true"
                :id="`popoverAccessInfo_${data.item.key}`"
            />
            <b-popover
                :target="`popoverAccessInfo_${data.item.key}`"
                triggers="hover"
                placement="right"
            >
              {{data.item.info}}
            </b-popover>
          </sup>
        </span>
      </template>
      <template v-slot:cell(ADMIN)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(AUTHOR)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(WRITER)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(READER)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(CUSTOM)="data">
        <span v-if="showEdit">
          <b-form-checkbox
            v-model="selectedFunctions[data.item.key]"
            switch
            @change="startEditing"
          />
        </span>
        <span v-else>
          <check-icon :show="data.value" />
        </span>
      </template>
    </b-table-lite>
    <div v-if="showEdit">
      <div class="mt-3 text-right">
        <b-button
          @click="buttonClicked"
          variant="primary"
          class="mr-3"
          :disabled="!isEditing"
        >
          Save
        </b-button>
        <b-button @click="cancel" variant="dark" :disabled="!isEditing">
          Cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import CheckIcon from "../interface/CheckIcon";

export default {
  name: "RoleLegend",
  components: {
    CheckIcon,
  },
  props: ["orgId", "showEdit"],
  computed: {
    ...mapState({
      orgs: state => state.role.orgs,
      allPermissions: state => state.role.allPermissions,
      user: state => state.auth.currentUser,
    }),
    ...mapGetters("role", {roles: "getRolesWithKey"}),
    customPermissions() {
      if (this.orgId && this.orgs[this.orgId]) {
        return this.orgs[this.orgId];
      }
      return [];
    },

    fields() {
      const fields = [{key: "label", label: "Permissions", class: "fun"}];
      fields.push(
        ...this.roles.map(role => {
          return {
            key: role.value,
            label: role.text,
            formatter: this.hasRole,
            class: "text-center",
          };
        })
      );
      if (this.customPermissions.length === 0 && !this.showEdit) {
        const index = fields.findIndex(r => r.key === "CUSTOM");
        if (index >= 0) {
          fields.splice(index, 1);
        }
      }
      return fields;
    },
    items() {
      let custom = this.customPermissions;
      if (!custom || custom.length <= 0) {
        custom = [];
      }
      return this.allPermissions.map(perm => {
        const roles = [...perm.inRoles];
        if (custom.includes(perm.key)) {
          roles.push("CUSTOM");
        }
        return {
          key: perm.key,
          label: perm.label,
          info: perm.info,
          inRoles: roles,
        };
      });
    },
    needsOrgRead() {
      return this.selectedFunctions.ADMIN;
    },
    removeOrgRead() {
      return !this.selectedFunctions.OF_VIEW;
    },
    needsGrantAppRead() {
      return (
        this.selectedFunctions.GRANT_APP_CREATE ||
        this.selectedFunctions.GRANT_APP_WRITE
      );
    },
    removeGrantAppRead() {
      return !this.selectedFunctions.GRANT_APP_READ;
    },
    breakpoint() {
      return this.showEdit ? "md" : "lg";
    },
  },
  data() {
    return {
      isEditing: false,
      selectedFunctions: {
        ADMIN: false,
        OF_VIEW: false,
        GRANT_APP_CREATE: false,
        GRANT_APP_WRITE: false,
        MAYBE_SUBMIT: false,
        GRANT_APP_READ: false,
      },
    };
  },
  methods: {
    ...mapActions("role", ["getCustomPermissions", "setCustomPermissions"]),
    hasRole(value, key, item) {
      return item.inRoles.includes(key);
    },
    buttonClicked() {
      if (this.isEditing) {
        this.save();
      }
      this.isEditing = !this.isEditing;
    },
    startEditing() {
      this.isEditing = true;
    },
    save() {
      const permissions = Object.entries(this.selectedFunctions)
        .filter(([key, value]) => value)
        .map(([key, value]) => key);

      const data = {
        orgId: this.orgId,
        permissions: permissions,
      };

      if (permissions.length <= 0) {
        this.showConfirmDialog().then(confirmed => {
          if (confirmed) {
            this.sendToBackend(data);
          } else {
            this.cancel();
          }
        });
      } else {
        this.sendToBackend(data);
      }
    },
    cancel() {
      this.allPermissions.map(p => {
        this.selectedFunctions[p.key] = this.customPermissions.includes(p.key);
      });
      this.isEditing = false;
      this.$bvToast.toast("Custom permission changes has been discarded", {
        title: "Cancel",
        autoHideDelay: 5000,
        variant: "success",
        solid: true,
      });
    },
    showConfirmDialog() {
      const message =
        "Removing all permissions from the custom role will prevent users in" +
        " this role from performing any action on behalf of your organisation.";
      const question = "Are you sure you want to proceed?";
      const h = this.$createElement;

      return this.$bvModal.msgBoxConfirm(
        [h("p", message), h("div", question)],
        {cancelVariant: "dark"}
      );
    },
    sendToBackend(data) {
      this.setCustomPermissions(data)
        .then(() => this.$root.$emit("bv::refresh::table", "members"))
        .then(() =>
          this.$bvToast.toast("Custom permission updated", {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true,
          })
        )
        .catch(error => {
          const errorCode = error.status || error.response.status;
          let message =
            "We couldn't save the permissions, please try again later";
          if (errorCode && errorCode === 400) {
            // Something is wrong with the data sent
            message = "Invalid permission set";
          }
          this.$bvToast.toast(message, {title: "Permissions not saved"});
        });
    },
  },
  mounted() {
    this.getCustomPermissions(this.orgId)
      .then(permissions => {
        permissions.map(perm => (this.selectedFunctions[perm] = true));
      })
      .catch(error => {
        console.log("Error when requesting the custom permissions: " + error);
      });
  },
  watch: {
    needsOrgRead(needsRead) {
      if (needsRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.OF_VIEW = true;
        });
      }
    },
    removeOrgRead(removeOrgRead) {
      if (removeOrgRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.ADMIN = false;
        });
      }
    },
    needsGrantAppRead(needsRead) {
      if (needsRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.GRANT_APP_READ = true;
        });
      }
    },
    removeGrantAppRead(removeGrantAppRead) {
      if (removeGrantAppRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.GRANT_APP_CREATE = false;
          this.selectedFunctions.GRANT_APP_WRITE = false;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../content/scss/vendor";
::v-deep td.fun {
  font-weight: 600;
  background-color: $primary_alpha_10;
}

::v-deep .custom-switch .custom-control-label::after {
  background-color: $gray-700;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: $dark_teal;
  background-color: $dark_teal;
}

.btn.disabled {
  opacity: 0.75;
}
</style>
