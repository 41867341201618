import { render, staticRenderFns } from "./BankAcctListView.vue?vue&type=template&id=19351075&scoped=true&"
import script from "./BankAcctListView.vue?vue&type=script&lang=js&"
export * from "./BankAcctListView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19351075",
  null
  
)

export default component.exports